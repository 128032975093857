.t-a-c{
  text-align: center!important;
}

.m-b-4rem{
  margin-bottom: 4rem!important;
}

.t-t-u{
  text-transform: uppercase!important;
}

.h-1-rem{
  height: 1rem;
}
.h-2-rem{
  height: 2rem;
}
.h-3-rem{
  height: 3rem;
}
.h-4-rem{
  height: 4rem;
}
.h-5-rem{
  height: 5rem;
}
.h-6-rem{
  height: 6rem;
}

.clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.open-sans-italic{
  @include opensans-italic;
}

.f-s-0_87-rem{
  font-size: .87rem;
}

@mixin link {
  position: relative;

  &:after, &:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #c3bdc6;
    transition: .3s;
  }
  &:after{
    left: 50%;
    right: 50%;
    background-color: #242424;
  }
  &:hover:after{
    left: 0;
    right: 0;
  }
}
@mixin link_2 {
  position: relative;

  &:after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 1px;
    transition: .3s;
    left: 50%;
    right: 50%;
    background-color: #242424;
  }
  &:hover:after,
  &._active:after{
    left: 0;
    right: 0;
  }
}
@mixin link_3 {
  color: #242424;
  text-decoration: none;
  border-bottom: 1px solid #c3bdc6;
  transition: border-color .3s;
  &:hover{
    border-color: #242424;
  }
}
@mixin title-link {
  color: inherit;
  text-decoration: none;
  transition: color .3s;
  &:hover{
    color: #EC1C24;
  }
}

$mobile: screen and (max-width: 767px);
$desktopWidth: 1220px;