.footer{

  &__basic{
    background-color: #DCDDDE;
    padding: 4.5rem 0;
  }

  &__bottom{
    background-color: #e9e7ea;
    padding: 1.25rem 0;
    font-size: .75rem;
    @media #{$mobile} {
      font-size: .85rem;
      text-align: center;
    }
  }

  &__copyright{
    display: inline-block;
    margin: 0 1rem 0 0;
    @media #{$mobile} {
      display: block;
      margin: 0 0 10px 0;
    }
  }

  &__sitemap-link{
    color: #242424;
    @include link_2;
    opacity: .6;
    @media #{$mobile} {
      display: inline-block;
      margin: 0 0 10px;
    }
  }

  &__develop{
    float: right;
    @media #{$mobile} {
      float: none;
    }

    a{
      color: #242424;
      transition: .3s;
      &:hover{
        color: #eb1c23;
      }
    }
  }
}

.f-logo{
  display: block;
  width: 120px;
  height: 120px;
  fill: #eb1c23;
  @media #{$mobile} {
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
}

.f-menu{
  display: flex;
  width: 100%;
  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__big-items{
    display: flex;
    flex-wrap: wrap;
    @media #{$mobile} {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  &__big-item{
    width: 50%;
    text-transform: uppercase;
    font-size: .75rem;
    @media #{$mobile} {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      margin: 0 0 14px 0;
    }

    a{
      color: inherit;
      @include link_2;
    }
  }

  &__small-items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media #{$mobile} {
      width: 100%;
    }
  }

  &__small-item{
    font-size: .75rem;
    margin: 0 0 .5rem 0;
    @media #{$mobile} {
      text-align: center;
      font-size: .85rem;
      margin: 0 0 10px 0;
    }

    a{
      color: inherit;
      @include link_2;
    }
  }
}

.f-social{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 27px 0;
}

.f-services-links{
  font-size: .75rem;
  line-height: 2;
  opacity: .6;
  @media #{$mobile} {
    font-size: .85rem;
    text-align: center;
  }

  a{
    color: #242424;
    @include link_2;
  }
}