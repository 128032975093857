@font-face {
  font-family: 'opensans';
  src: url('/fonts/opensans.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'opensans-italic';
  src: url('/fonts/opensans-italic.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'bebas';
  src: url('/fonts/bebas.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'bebas-book';
  src: url('/fonts/bebas-book.woff') format('woff');
  font-style: normal;
}

@mixin arial {
  font-family: 'Arial', sans-serif;
  font-weight: normal;
}

@mixin times {
  font-family: 'Times New Roman', serif;
  font-weight: normal;
}

@mixin opensans {
  font-family: 'opensans', 'Arial', sans-serif;
  font-weight: normal;
}

@mixin opensans-italic {
  font-family: 'opensans-italic', 'Arial', sans-serif;
  font-weight: normal;
}

@mixin bebas {
  font-family: 'bebas', 'Arial', sans-serif;
  font-weight: normal;
}

@mixin bebas-book {
  font-family: 'bebas-book', 'Arial', sans-serif;
  font-weight: normal;
}

