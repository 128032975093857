.section-head{
  background-position: center center;
  background-size: cover;
  background-color: #e9e7ea;

  &._overlay-opacity{
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #E9E7EA;
      opacity: .6;
    }
  }

  .container{
    height: 100vh;
    max-height: 680px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    padding-top: 180px;
    padding-bottom: 100px;
    @media #{$mobile} {
      padding-top: 90px;
      padding-bottom: 35px;
    }
  }
  &._auto-height .container{
    height: auto;
    min-height: 550px;
    max-height: none;
    @media #{$mobile} {
      min-height: 0;
    }
  }

  .breadcrumbs{
    margin: 0 0 3.12rem 0;
    @media #{$mobile} {
      display: none;
    }
  }

  &__title{
    max-width: 500px;
    @include bebas-book;
    font-size: 3.75rem;
    line-height: 1;
    @media #{$mobile} {
      font-size: 3.4rem;
      max-width: 100%;
      word-wrap: break-word;
    }

    &__min-text{
      font-size: 1.5rem;
      @media #{$mobile} {
        font-size: 1.7rem;
      }
    }
  }
  &._text-full-width &__title{
    max-width: none;
  }

  h1{
    max-width: 500px;
    @media #{$mobile} {
      font-size: 3.4rem;
      max-width: 100%;
      word-wrap: break-word;
    }

    .min{
      font-size: 1.5rem;
      line-height: 1;
      display: block;
      @media #{$mobile} {
        font-size: 1.7rem;
      }
    }
  }
  &._text-full-width h1{
    max-width: none;
  }

  &__descr{
    max-width: 500px;
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media #{$mobile} {
      max-width: 100%;
    }
  }
  &._text-full-width &__descr{
    max-width: none;
  }

  &__btns{
    margin: 2rem 0 0 0;
  }

  &__author{
    position: absolute;
    top: 60%;
    right: 0;
    text-align: center;
    @media #{$mobile} {
      display: none;
    }

    &__name{
      @include bebas-book;
      font-size: 1.5rem;
    }

    &__post{
      @include opensans-italic;
      color: #494949;
    }

    &__blockquote{
      display: inline-block;
      margin: 10px 0 0 0;
    }
  }

  &__text-red-bg{
    display: inline-block;
    background-color: #EC1C24;
    font-size: 1.5rem;
    @include bebas-book;
    color: white;
    line-height: 1;
    padding: 6px 10px 1px;
    margin: 0 0 20px 0;
  }

  &__sert{
    @include opensans-italic;
    color: #494949;
    font-size: .87rem;
    max-width: 350px;
    margin: 20px 0 0 0;
  }
}

.head-slider{
  overflow: hidden;
  position: relative;

  &__slide{
    background-position: center 0;
    background-size: cover;
  }

  &__pagination{
    position: absolute;
    bottom: 56px!important;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 16px;

    .bullet{
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #242424;
      margin: 0 14px 0 0;
      transition: .3s;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &._active{
        width: 16px;
        height: 16px;
        border: 2px solid #242424;
        background: none;
      }
    }
  }
}