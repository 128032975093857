.section-menu{
  @include bebas;
  padding: 40px 0;
  @media #{$mobile} {
    padding: 25px 0;
  }

  &__list{
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$mobile} {
      display: none;
    }
  }

  &__item{
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    position: relative;
    @media #{$mobile} {
      display: block;
      padding: 15px 0 0 0;
    }
    &._active{
      @media #{$mobile} {
        color: #ec1c24;
      }
    }

    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      right: 50%;
      height: 1px;
      background-color: #242424;
      transition: .3s;
      @media #{$mobile} {
        display: none;
      }
    }
    &:hover:after{
      left: 0;
      right: 0;
    }
    &._active:after{
      left: 0;
      right: 0;
    }
  }

  &__separator{
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 17px;
    background-color: #242424;
    margin: -.2rem .8rem 0;
    @media #{$mobile} {
      display: none;
    }
  }

  &__mob-head{
    display: none;
    @media #{$mobile} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;

      &__arrow{
        width: 12px;
        height: 7px;
        margin: -4px 0 0 0;
        position: relative;
        transition: .3s;
        &:before{
          content: '';
          position: absolute;
          top: -10px;
          right: -10px;
          bottom: -10px;
          left: -10px;
        }
        &._active{
          transform: rotate(180deg);
        }

        .svg-icon{
          display: block;
        }
      }
    }
  }
}