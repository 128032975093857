header.header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 27px 0;
  z-index: 9;
  min-width: #{$desktopWidth};
  @media #{$mobile} {
    min-width: 320px;
  }
  .container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.h_logo{
  display: block;
  width: 120px;
  height: 120px;
  fill: #eb1c23;
  position: relative;
  z-index: 1;
  @media #{$mobile} {
    width: 60px;
    height: 60px;
  }
}

.h_gamburger{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 17px 0 0 0;
  width: 16px;
  height: 12px;
  position: relative;
  z-index: 1;

  &:before{
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
  i{
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000000;
    transition: .3s;
  }
  &:hover i{
    background-color: #eb1c23;
  }
}

.main-menu-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .97);
  padding: 27px 0;
  z-index: 9;
  display: none;
  overflow: hidden;
  @media #{$mobile} {
    background: white;
  }

  >.container{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.m-menu-x{
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17px;
  right: 20px;

  i{
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -50%;
    &:first-child{
      transform: rotate(45deg);
    }
    &:last-child{
      transform: rotate(-45deg);
    }
    background-color: #000000;
    transition: .3s;
  }
  &:hover i{
    background-color: #eb1c23;
    &:first-child{
      transform: rotate(35deg);
    }
    &:last-child{
      transform: rotate(-35deg);
    }
  }
}

.main-nav{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 40px 0 20px 140px;
  @media #{$mobile} {
    display: none;
  }
  &._fix{
    z-index: 10;
    position: fixed;
    background-image: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,0) 100%);;
  }

  &__item{
    font-size: .75rem;
    text-transform: uppercase;
    margin: 0 1.6rem 0 0;

    a{
      color: inherit;
      @include link_2;
      &._active:after{
        left: 0;
        right: 0;
      }
    }
  }
}

.main-menu{
  margin: 0 0 0 225px;
  width: 290px;
  position: relative;
  @media #{$mobile} {
    margin: 60px 0 0 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 60px);
    width: 100%;
  }

  &__items-wrapper{
    @media #{$mobile} {
      overflow: hidden;
      padding: 0 10px 0 0;
    }
  }

  &__item{
    @include bebas-book;
    font-size: 1.75rem;
    line-height: 1;
    padding: .7rem 0;
    @media #{$mobile} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__link{
      @include title-link;
      @media #{$mobile} {
        &._active{
          color: #EC1C24;
        }
      }
    }
    &._active &__link{
      color: #EC1C24;
    }

    &__mob-arrow{
      display: none;
      @media #{$mobile} {
        display: inline-block;
        width: 5px;
        height: 9px;
        fill: #888888;
        position: relative;

        &:before{
          content: '';
          position: absolute;
          top: -10px;
          right: -10px;
          bottom: -10px;
          left: -10px;
        }

        .svg-icon{
          display: block;
        }
      }
    }
  }

  &__second{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 285px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    @media #{$mobile} {
      left: 0;
      width: 100%;
      background: white;
      z-index: 1;
      transform: translateX(-100%);
    }

    &__mob-arrow{
      display: none;
      @media #{$mobile} {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 13px;

        .svg-icon{
          display: block;
        }
      }
    }
    
    &__mob-title{
      display: none;
      @media #{$mobile} {
        display: block;
        @include bebas-book;
        font-size: 2rem;
        line-height: 1;
        margin: 0 0 20px 0;
      }
    }
  }
  &__item._active &__second{
    opacity: 1;
    visibility: visible;
    @media #{$mobile} {
      transform: translateX(0);
    }
  }

  &__second-item{
    font-size: .75rem;
    text-transform: uppercase;
    @include opensans;
    padding: 1rem 0;
    @media #{$mobile} {
      font-size: 1rem;
    }

    a{
      @include title-link;
    }
    &._active > a{
      color: #EC1C24;
    }
  }

  &__third-item{
    font-size: .75rem;
    @include opensans;
    padding: .2rem 0 .2rem 1rem;

    a{
      @include title-link;
    }
    &._active > a{
      color: #EC1C24;
    }
  }

  &__item-content{
    position: absolute;
    top: 0;
    left: calc(100% + 285px);
    width: 300px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    @media #{$mobile} {
      display: none;
    }
    &._active{
      opacity: 1;
      visibility: visible;
    }

    &__img{
      margin: 0 0 20px 0;

      img{
        display: block;
        max-width: 100%;
      }
    }

    &__title{
      @include bebas-book;
      font-size: 1.5rem;
      margin: 0 0 12px 0;
    }

    &__descr{
      font-size: .87rem;
      line-height: 1.5;
      color: #484848;
      @include opensans;
    }
  }
}

.m-menu-search{
  width: 14px;
  height: 15px;
  position: absolute;
  top: 50%;
  right: 20px;
  @media #{$mobile} {
    display: none;
  }

  &:hover .svg-icon{
    fill: #EC1C24;
  }
}

.m-menu-logo{
  position: absolute;
  top: 0;
  left: 20px;
}