html{
  font-size: 16px;
  line-height: 1.5;
  color: #242424;
  box-sizing: border-box;
  @media #{$mobile} {
    font-size: 14px;
  }

  *{
    box-sizing: inherit;
  }
}

body{
  @include opensans;
}

::selection{
  background-color: #c3bdc6;
  color: white;
}
.main {
  position: relative;
}
.page{
  overflow: hidden;
  min-width: #{$desktopWidth};
  @media #{$mobile} {
    min-width: 320px;
  }

  &._404{
    height: 100vh;
    background-color: #e9e7ea;
    background-image: url("/img/page-404-bg.jpg");
    background-size: cover;
    background-position: center center;
    min-width: auto;
  }
}

.container{
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  @media #{$mobile} {
    padding: 0 25px;

    .container{
      padding-left: 0;
      padding-right: 0;
    }
  }
  &._min{
    max-width: 800px;
  }
  &._mid{
    max-width: 1010px;
  }
}

h1{
  font-weight: normal;
  @include bebas-book;
  font-size: 3.75rem;
  line-height: 1;
  @media #{$mobile} {
    font-size: 3.4rem;
  }
}

h2{
  font-weight: normal;
  @include bebas-book;
  font-size: 3rem;
  line-height: 1;
  margin: 0 0 2rem 0;
  @media #{$mobile} {
    font-size: 2.8rem;
    margin-bottom: 1.7rem;
  }

  .min{
    font-size: 1.25rem;
    display: block;
  }
}

h3{
  font-weight: normal;
  @include bebas-book;
  font-size: 2.25rem;
  line-height: 1;
  margin: 0 0 1.5rem 0;
}

h4{
  font-weight: normal;
  @include bebas-book;
  font-size: 1.75rem;
  line-height: 1;
  margin: 0 0 1rem 0;
  @media #{$mobile} {
    font-size: 2rem;
  }
}

hr{
  border: none;
  height: 1px;
  background-color: #D4D0D5;
  margin: 3rem 0;
}

ul{

  li{
    display: flex;
    margin: .62rem 0;
    position: relative;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }

    &:before{
      flex-shrink: 0;
      content: '';
      background-color: #ec1c24;
      width: 1rem;
      height: 1px;
      margin: .8rem .8rem 0 0;
    }
    ul{
      padding: .62rem 0 0 2.9rem;
    }
  }
}

ol{
  counter-reset: myCounter;

  li{
    margin: .62rem 0;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }

    &:before{
      counter-increment: myCounter;
      content:counter(myCounter)'.';
      color: #ec1c24;
      display: inline-block;
      width: 1.5rem;
    }

    ol{
      counter-reset: myCounter2;
      padding: .62rem 0 0 2.9rem;

      li{

        &:before{
          counter-increment: myCounter2;
          content:counter(myCounter)'.'counter(myCounter2)'.';
          width: 2.2rem;
        }
      }
    }
  }
}

.blockquote{
  max-width: 600px;
  margin: 0 auto;

  blockquote{
    background-image: url('/img/blockquote.svg');
    background-repeat: no-repeat;
    background-position: .4rem 0;
    font-size: 1.12rem;
    @include opensans-italic;
    color: #888888;
    text-align: center;
    padding: 1rem 0 0 0;
  }

  &__author{
    margin: 1.5rem 0 0 0;
    text-align: center;

    &__photo{
      margin: 0 0 .8rem 0;

      img{
        display: block;
        margin: 0 auto;
        border-radius: 50%;
      }
    }

    &__name{
      @include bebas-book;
      font-size: 1.25rem;
    }
  }
}

i{
  @include opensans-italic;
}

.table-wrapper{
  @media #{$mobile} {
    margin: 0 -25px;
    padding: 0 25px;
    overflow-x: auto;
  }
}

table.table{
  width: 100%;

  tr{

    th{
      background-color: #DEDBDF;
      @include bebas;
      font-size: 1.25rem;
      text-align: center;
      padding: 1rem 1.5rem;
    }

    td{
      font-size: .87rem;
      border-bottom: 1px solid #DEDBDF;
      border-right: 1px solid #DEDBDF;
      text-align: left;
      padding: 1rem 1.5rem;
      &:last-child{
        border-right: none;
      }
    }
  }
}

.btn{
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  border: 2px solid #242424;
  padding: .5rem 1rem;
  text-transform: uppercase;
  font-size: .87rem;
  color: #242424;
  position: relative;
  transition: .2s;
  @media #{$mobile} {
    font-size: 1rem;
  }
  &:hover:not(._disabled){
    color: white;
  }
  &._disabled{
    cursor: default;
    border-color: #E4DFE3;
    color: #A8A8A8;
    pointer-events: none;
  }

  &:before{
    content: '';
    background-color: #242424;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    transition: .3s;
    opacity: 0;
  }
  &:not(._disabled):hover:before{
    left: 0;
    right: 0;
    opacity: 1;
  }

  span{
    position: relative;
  }
}

.list-item-title{
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0 0 .56rem 0;
  @media #{$mobile} {
    font-size: 1.1rem;
  }

  a{
    @include title-link;
  }
}

.list-item-descr{
  font-size: .87rem;
  line-height: 1.25;
  color: #484848;
}

.section{
  padding: 4rem 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &._bg-1{
    background-color: #e9e7ea;
  }
  &._bg-2{
    background-color: #F5F5F5;
  }
  &._bg-3 {
    background-color: #faf9f9;
  }  
  &._bg-4 {
    background-color: #ffffff;
  }
  &._border-top{
    border-top: 1px solid #D4D0D5;
  }
  &._border-bottom{
    border-top: 1px solid #D4D0D5;
  }
  &._sect-pad-80 {
    padding: 5rem 0;
  }
  &._sect-pad-100 {
    padding: 6.25rem 0;
  }
  &._sect-preview{
    min-height: 540px;
    display: flex;
    align-items: center;
    background-position: center center;
    background-size: cover;
    &_tall {
      min-height: 680px;
    }
    @media #{$mobile} {
      min-height: 320px;
      text-align: center;

      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(227, 227, 227, .5);
        z-index: 0;
      }
    }

    > .container{
      z-index: 2;
    }
  }
  &._overlay-opacity{
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #E9E7EA;
      opacity: .6;
    }
  }

  &__shadow-left{
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 1000px;
    right: 50%;
    background: linear-gradient(to right, rgba(233,231,234,1) 0%,rgba(233,231,234,1) 60%,rgba(233,231,234,0) 70%,rgba(233,231,234,0) 100%);
    @media #{$mobile} {
      display: none;
    }
  }
  &._align-left &__shadow-left{
    background: linear-gradient(to right, rgba(233,231,234,1) 0%,rgba(233,231,234,1) 80%,rgba(233,231,234,.5) 90%,rgba(233,231,234,0) 100%);
  }
  &._align-left &__shadow-right{
    background: linear-gradient(to right, rgba(233,231,234,0) 0%,rgba(233,231,234,0) 50%,rgba(233,231,234,1) 60%,rgba(233,231,234,1) 100%);
  }

  &__shadow-right{
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 1000px;
    left: 50%;
    background: linear-gradient(to right, rgba(233,231,234,0) 0%,rgba(233,231,234,0) 30%,rgba(233,231,234,1) 40%,rgba(233,231,234,1) 100%);
    @media #{$mobile} {
      display: none;
    }
  }
  &._align-right &__shadow-right{
    margin-left: -5%;
    background: linear-gradient(to right, rgba(233,231,234,0) 0%,rgba(233,231,234,.5) 10%,rgba(233,231,234,1) 20%,rgba(233,231,234,1) 100%);
  }
  &._align-right &__shadow-left{
    background: linear-gradient(to right, rgba(233,231,234,1) 0%,rgba(233,231,234,1) 40%,rgba(233,231,234,0) 50%,rgba(233,231,234,0) 100%);
  }

  &__shadow-bg{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 800px;
    left: 50%;
    margin-left: -400px;
    z-index: 0;
    @media #{$mobile} {
      display: none;
    }
  }
  &._align-left &__shadow-bg{
    margin-left: -200px;
  }
  &._align-right &__shadow-bg{
    margin-left: -600px;
  }

  &__menu{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media #{$mobile} {
      margin: 0 -25px;
    }

    &__item{
      @include bebas;
      font-size: 1.5rem;
      @media #{$mobile} {
        margin: 0 .7rem;
      }

      &:after{
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 1.2rem;
        background-color: #242424;
        margin: 0 1rem;
        @media #{$mobile} {
          display: none;
        }
      }
      &:last-child:after{
        display: none;
      }

      a{
        color: #242424;
        @include link_2;
      }
    }
  }
}

.link {
  @include link;
}
.link_2 {
  @include link_2;
}

.text{
  &._min{
    font-size: .87rem;
  }

  a{
    color: inherit;
    display: inline-block;
    @include link;
  }

  b, strong{
    @include arial;
    font-weight: bold;
  }
}

//для svg-спрайтов
.svg-icon{
  width: 100%;
  height: 100%;
  transition: .3s;

  *{
    fill: inherit;
    stroke: inherit;
  }
}

.breadcrumbs{
  text-transform: uppercase;
  font-size: .62rem;

  &__item:not(span){
    color: #888888;
    text-decoration: none;
  }

  &__separator{
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 13px;
    background-color: #888888;
    margin: -2px 10px 0;
  }
}

.tabs{

  &__head{
    display: flex;
    justify-content: center;
    margin: 0 0 2.5rem 0;
    @media #{$mobile} {
      margin-right: -25px;
      margin-left: -25px;
      padding: 0 25px;
      white-space: nowrap;
      display: block;
      overflow-x: auto;
      text-align: center;
    }

    &__item{
      margin: 0 1.5rem 0 0;
      @media #{$mobile} {
        display: inline-block;
        margin-right: 1rem;
      }
      &:last-child{
        margin-right: 0;
      }

      &:before{
        display: none;
      }

      a{
        @include bebas-book;
        font-size: 1.5rem;
        line-height: 1;
        color: #242424;
        position: relative;

        &:after{
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          right: 50%;
          height: 1px;
          background-color: #242424;
          transition: .3s;
        }
        &.active:after,
        &:hover:after{
          left: 0;
          right: 0;
        }
      }
    }
  }
}

.accordion-group{}
.accordion{
  border-bottom: 1px solid #C3BDC6;
  &:first-child{
    border-top: 1px solid #C3BDC6;
  }

  &__control{
    margin: 2rem 0;
    @include bebas;
    font-size: 1.5rem;
    position: relative;
    padding: 0 0 0 4.25rem;
    cursor: pointer;
    @media #{$mobile} {
      margin: 24px 0;
    }

    &:before{
      content: '';
      width: 2rem;
      height: 1px;
      background-color: #ec1c24;
      position: absolute;
      left: 0;
      top: 50%;
      margin: -.2rem 0 0 0;
      transition: .3s;
    }
    &:hover:before{
      width: 2.8rem;
    }
  }
  &.open &__control:before{
    width: 2.8rem;
  }

  &__element{

    >div{
      padding: 0 0 2rem 0;
    }
  }
}

.pagination{
  display: flex;
  justify-content: center;

  >*{
    margin: 0 1.5rem 0 0;
    &:last-child{
      margin-right: 0;
    }
  }

  &__prev, &__next{
    height: .5rem;
    width: 2.12rem;
    fill: #888888;
    &:hover{
      fill: #242424;
    }
  }

  &__link{
    color: #888888;
    transition: .3s;
    &:hover{
      color: #242424;
    }
  }

  &__ellipsis{
    color: #888888;
  }

  &__separatior{
    color: #242424;
  }
}

.gallery-container{
  position: relative;
  user-select: none;
}

.gallery{
  overflow: hidden;
  margin: 0 0 1.3rem 0;
  @media #{$mobile} {
    margin-right: -25px;
    margin-left: -25px;
  }

  &__slide{

    img{
      max-width: 100%;
    }
  }

  &__container{
    position: relative;
  }
}

.gallery-nav{

  &__prev, &__next{
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    height: 8px;
    width: 34px;
    fill: #888888;
    cursor: pointer;
    z-index: 1;
    @media #{$mobile} {
      fill: #242424;
    }
    &:hover{
      fill: #242424;
    }
    &.swiper-button-disabled{
      opacity: 0;
      visibility: hidden;
    }

    .svg-icon{
      display: block;
    }
  }
  &__prev{
    left: -50px;
    @media #{$mobile} {
      left: 0;
    }
  }
  &__next{
    right: -50px;
    @media #{$mobile} {
      right: 0;
    }
  }
}

.gallery-prev{
  overflow: hidden;
  @media #{$mobile} {
    margin-right: -25px;
    margin-left: -25px;
  }

  &__wrapper{}

  &__slide{
    width: 9rem;
    transition: .3s;
    @media #{$mobile} {
      width: 60px;
    }
    &.swiper-slide-active{
      opacity: .4;
    }

    img{
      display: block;
      max-width: 100%;
    }
  }
}

.video{
  position: relative;
  overflow: hidden;
  min-height: 400px;
  background-position: center center;
  background-size: cover;

  &:after{
    display: none;
    @media #{$mobile} {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(224, 222, 226, .8);
    }
  }

  > .container{
    position: relative;
    padding: 50px 20px;
    z-index: 1;
  }

  &__name{
    font-size: 3.75rem;
    @include bebas-book;
    line-height: 1;
    @media #{$mobile} {
      font-size: 2.5rem;
    }

    .min{
      font-size: 1.25rem;
      display: block;
    }
  }

  &__play{
    width: 5rem;
    height: 5rem;
    margin: -2.5rem 0 0 -2.5rem;
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    svg{
      fill: white;
      width: .68rem;
      height: .87rem;
    }
  }

  &__masque{
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1925px 0 0 -1925px;
    @media #{$mobile} {
      display: none;
    }
  }
}

.img-in-text{
  display: block;
  max-width: 100%;
  @media #{$mobile} {
    width: 100%;
  }
  &._right{
    max-width: 384px;
    float: right;
    margin: 0 0 2rem 2rem;
    @media #{$mobile} {
      max-width: 100%;
      margin-right: 0;
    }
  }
  &._left{
    max-width: 384px;
    float: left;
    margin: 0 2rem 2rem 0;
    @media #{$mobile} {
      max-width: 100%;
      margin-left: 0;
    }
  }
}

.news{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.18rem 0 0;
  @media #{$mobile} {
    margin: 0;
  }

  &__grid{
    width: calc(25% - 2.18rem);
    margin: 0 2.18rem 2.18rem 0;
    @media #{$mobile} {
      width: 100%;
      margin: 0 0 25px 0;
    }
  }
}

.news-item{
  display: block;
  text-decoration: none;
  color: #242424;

  &__img{
    margin: 0 0 1.25rem 0;
    position: relative;
    transition: .3s;

    img{
      display: block;
      max-width: 100%;
    }

    &__line{
      position: absolute;
      background-color: white;
      transform: scale(0);
      transition: .3s;
      &._top{
        top: .5rem;
        left: .5rem;
        right: .5rem;
        height: 1px;
      }
      &._right{
        right: .5rem;
        top: .5rem;
        bottom: .5rem;
        width: 1px;
      }
      &._bottom{
        bottom: .5rem;
        left: .5rem;
        right: .5rem;
        height: 1px;
      }
      &._left{
        left: .5rem;
        top: .5rem;
        bottom: .5rem;
        width: 1px;
      }
    }
  }
  &:hover &__img{
    filter: saturate(1.3);

    &__line{
      transform: scale(1);
    }
  }

  &__title{
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin: 0 0 .56rem 0;
    transition: .3s;
  }
  &:hover &__title{
    color: #ec1c24;
  }

  &__preview{
    font-size: .87rem;
    line-height: 1.25rem;
    color: #484848;
  }
}

.search{

  &__count{
    @include opensans-italic;
    color: #888888;
    text-align: center;
    margin: 1rem 0 0 0;
  }

  &__res-list{
    padding: 2.5rem 0 0 0;
  }

  &__res-item{
    margin: 0 0 3.12rem 0;

    &__title{
      font-size: 1.25rem;
      margin: 0 0 1rem 0;

      a{
        color: inherit;
        text-decoration: none;
        transition: .3s;
        &:hover{
          color: #888888;
        }

        span, b{
          font-weight: normal;
          color: #B4A0A1;
        }
      }
    }

    &__text{

      span, b{
        font-weight: normal;
        color: #B4A0A1;
      }
    }
  }
}

.search-form{
  max-width: 592px;
  margin: 0 auto;
  position: relative;

  input[type=text]{
    appearance: none;
    outline: none;
    width: 100%;
    height: 3.5rem;
    border: 0;
    border-radius: 0;
    background-color: white;
    font-size: 1rem;
    color: #242424;
    @include opensans;
    padding: 0 1rem;
  }

  input[type=submit]{
    appearance: none;
    outline: none;
    border: none;
    border-radius: 0;
    background: url("/img/search.svg");
    background-size: 100% 100%;
    width: .87rem;
    height: .87rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin: -.43rem 0 0 0;
    right: 1rem;
  }
}

.file{
  display: flex;
  margin: 0 0 2rem 0;

  &__icon{
    width: 1.5rem;

    .svg-icon{
      width: 1rem;
      height: 1.25rem;
      fill: none;
      stroke: #ec1c24;
    }
  }

  &__text{}

  &__name{
    @include bebas-book;
    font-size: 1.5rem;
    line-height: 1;

    a{
      color: #242424;
    }
  }

  &__format{
    display: block;
    color: #888888;
    font-size: .87rem;
  }
}

.hidden{
  display: none;
}

.fancybox-bg{
  background-color: #E9E7EA;
}
.fancybox-content{
  padding: 2.5rem 2rem;
}

.popup-form{
  width: 500px;
}

.section-preview{

  &__menu{
    margin-top: 420px;
    @media #{$mobile} {
      margin-top: 200px;
    }
  }
}

.section-main-products{
  background-color: white!important;
  background-image: url("/img/main-products-section-mob-bg.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 262px 261px;
  @media #{$mobile} {
    background-size: 192px 191px;
  }

  &__layer-particles{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  h2{
    position: relative;
    z-index: 1;
  }
}

.main-products{
  height: 400px;
  margin: 0 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media #{$mobile} {
    height: auto;
    min-height: 400px;
  }

  &__oval-one,
  &__oval-two{
    position: absolute;
    top: 50%;
    left: 50%;
    transition: 1s;
    @media #{$mobile} {
      display: none;
    }
  }
  &__oval-one{
    margin: -297px 0 0 -371px;
    &[data-state="1"]{
      transform: rotate(-20deg);
    }
    &[data-state="2"]{
      transform: rotate(-5deg);
    }
    &[data-state="3"]{
      transform: rotate(15deg);
    }
    &[data-state="4"]{
      transform: rotate(5deg);
    }
  }
  &__oval-two{
    margin: -302px 0 0 -306px;
    transform: rotate(4deg);
    &[data-state="1"]{
      transform: rotate(32deg);
    }
    &[data-state="2"]{
      transform: rotate(18deg);
    }
    &[data-state="3"]{
      transform: rotate(88deg);
    }
    &[data-state="4"]{
      transform: rotate(-20deg);
    }
  }
}

.main-products-slider{
  overflow: hidden;
  width: 300px;
  //margin: 0 auto;
  @media #{$mobile} {
    width: auto;
  }

  &__wrapper{

    @media #{$mobile} {
      display: block;
    }
  }

  &__slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media #{$mobile} {
      margin: 15px 0;
    }
  }

  &__mob-title{
    display: none;
    @media #{$mobile} {
      display: inline-block;
      @include bebas-book;
      color: #242424;
      font-size: 1.5rem;
      white-space: nowrap;
      line-height: 1;
      padding: .3rem .5rem 0;
      transition: .3s;
      &._active{
        background-color: #EC1C24;
        color: white;
      }
    }
  }

  &__list{
    text-align: center;
    font-size: 1.3rem;
    @include bebas;
    @media #{$mobile} {
      display: none;
      padding: 15px 0 0 0;
    }

    &__mob-container{
      @media #{$mobile} {
        min-height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &__separator{
      width: 4px;
      height: 4px;
      background-color: #242424;
      border-radius: 50%;
      margin: 4px auto;
      opacity: .1;
    }

    &__item{

      a{
        @include title-link;
      }
    }
  }
}

.main-products-slider-nav{
  @media #{$mobile} {
    display: none;
  }

  &__item{
    position: absolute;
    @include bebas-book;
    color: #242424;
    font-size: 1.5rem;
    white-space: nowrap;
    display: inline-block;
    line-height: 1;
    padding: .3rem .5rem 0;
    transition: .3s;
    &:nth-child(1){
      top: 19%;
      left: 10%;
    }
    &:nth-child(2){
      bottom: 25%;
      left: 10.5%;
    }
    &:nth-child(3){
      top: 10%;
      right: 12.5%;
    }
    &:nth-child(4){
      top: 45%;
      right: 9.1%;
    }
    &:nth-child(5){
      bottom: 17%;
      right: 7.6%;
    }
    &._active{
      color: white;

      &:after{
        width: 65px;
      }

      > i{
        transition-delay: .7s;
        opacity: 1;
        visibility: visible;
      }
    }
    &:hover{
      color: white;
    }

    &:after{
      content: '';
      position: absolute;
      width: 0;
      height: 1px;
      background-color: #EC1C24;
      top: 50%;
      left: 100%;
      transition: .3s;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5){

      &:after{
        left: auto;
        right: 100%;
      }
    }

    > i{
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #EC1C24;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: calc(100% + 65px);
      opacity: 0;
      visibility: hidden;
      transition: .5s;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5){

      > i{
        left: auto;
        right: calc(100% + 65px);
      }
    }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
      background-color: #EC1C24;
      transition: .3s;
      opacity: 0;
      visibility: hidden;
    }
    &._active:before, &:hover:before{
      left: 0;
      right: 0;
      opacity: 1;
      visibility: visible;
    }

    span{
      position: relative;
    }
  }
}

.section-main-laennec{
  background-image: url('/img/main-laennec-bg.jpg');
  background-position: center center;
  @media #{$mobile} {
    background-color: #e4e2e3!important;
    background-image: url("/img/main-laennec-mob-bg.jpg");
    background-position: top left;
    background-size: auto;
    padding-top: 300px;
  }
}

.main-clinics{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item{
    display: block;
    width: 48%;
    position: relative;
    color: #242424;
    transition: .3s;
    @media #{$mobile} {
      width: 100%;
      margin: 0 0 25px 0;
    }
    &:hover{
      filter: saturate(1.3);
    }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(227, 227, 227, .5);
    }

    &__line{
      position: absolute;
      background-color: white;
      transform: scale(0);
      transition: .3s;
      &._top{
        top: .5rem;
        left: .5rem;
        right: .5rem;
        height: 1px;
      }
      &._right{
        right: .5rem;
        top: .5rem;
        bottom: .5rem;
        width: 1px;
      }
      &._bottom{
        bottom: .5rem;
        left: .5rem;
        right: .5rem;
        height: 1px;
      }
      &._left{
        left: .5rem;
        top: .5rem;
        bottom: .5rem;
        width: 1px;
      }
    }
    &:hover &__line{
      transform: scale(1);
    }

    &__bg{
      display: block;
      width: 100%;
      height: 100%;
    }

    &__content{
      position: absolute;
      bottom: 33px;
      left: 40px;
      right: 40px;
      @media #{$mobile} {
        left: 20px;
        bottom: 20px;
        right: 20px;
      }
    }

    &__name{
      @include bebas;
      font-size: 1.5rem;
      line-height: 1;
    }

    &__phone{
      margin: 1rem 0 0 0;
      font-size: .87rem;
    }
  }

  &__bottom-text{
    padding-top: 38px;
    width: 100%;
    text-align: center;
    @media #{$mobile} {
      padding-top: 15px;
    }
  }
}

.leadership-list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.18rem 0 0;
  @media #{$mobile} {
    margin: 0;
    justify-content: space-between;
  }

  &__item {
    display: block;
    text-decoration: none;
    color: #242424;
    width: calc(33.333% - 2.18rem);
    margin: 0 2.18rem 2.18rem 0;
    @media #{$mobile} {
      width: 48%;
      margin: 0 0 20px 0;
    }

    &__img{
      margin: 0 0 22px 0;

      img{
        display: block;
        width: 100%;
      }
    }

    &__name{
      font-size: 1.75rem;
      line-height: 1;
      @include bebas-book;
    }

    &__descr{
      margin: 9px 0 0 0;
      font-size: .87rem;
      color: #484848;
    }
  }
}

img{
  max-width: 100%;
}

.history{
  display: flex;
  height: 350px;
  @media #{$mobile} {
    height: auto;
    flex-wrap: wrap;
    margin: 0 -25px;
  }
}

.history-nav-slider{
  width: 105px;
  overflow: hidden;
  @media #{$mobile} {
    width: 100%;
    margin: 0 0 20px 0;
  }

  &__slide{
    @include bebas-book;
    color: #C3BDC6;
    font-size: 2.25rem;
    line-height: 1;
    cursor: pointer;
    height: auto;
    transition: .3s;
    @media #{$mobile} {
      width: auto;
      font-size: 2rem;
    }
    &._active{
      color: #EC1C24;
    }
  }
}

.history-slider{
  overflow: hidden;
  width: 100%;

  &__slide{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    background-color: #e9e7ea;
    @media screen and (min-width: 768px){
      background-image: none!important;
    }
    @media #{$mobile} {
      background-position: center center;
      background-size: cover;

      &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(277, 277, 277, .6);
      }
    }

    &__img{
      height: 100%;
      @media #{$mobile} {
        display: none;
      }

      img{
        display: block;
        max-height: 100%;
      }
    }

    &__text-content{
      width: 50%;
      @media #{$mobile} {
        width: 100%;
        position: relative;
        padding: 25px;
        z-index: 1;
      }
    }

    &__title{
      @include bebas-book;
      font-size: 3rem;
      line-height: 1;
      margin: 0 0 34px 0;
      @media #{$mobile} {
        margin-bottom: 20px;
      }
    }

    &__year{
      @include bebas-book;
      color: #242424;
      text-decoration: none;
      font-size: 3.75rem;
      position: absolute;
      top: 15%;
      left: 40%;
      @media #{$mobile} {
        display: none;
      }

      .svg-icon{
        width: 34px;
        height: 8px;
        fill: #242424;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 29px;
        transition: .3s;
      }
      &:hover .svg-icon{
        transform: translateX(.5rem);
      }
    }
  }
}

.rewards-slider{
  overflow: hidden;
  @media #{$mobile} {
  }

  &__slide{
    text-align: center;

    &__img{
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media #{$mobile} {
        height: 100px;
      }

      a{
        text-decoration: none;
        @media #{$mobile} {
          max-width: 100%;
          max-height: 100%;
        }
      }

      img{
        @media #{$mobile} {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    &__name{
      font-size: 1.5rem;
      line-height: 1;
      @include bebas-book;
      max-width: 220px;
      margin: 0 auto;

      a{
        @include title-link;
      }
    }

    .file{
      justify-content: center;
      margin: 10px 0 0 0;
    }
  }

  &__pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    bottom: auto;
    margin: 30px 0 0 0;
    height: 16px;

    .bullet{
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #242424;
      margin: 0 14px 0 0;
      transition: .3s;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &._active{
        width: 16px;
        height: 16px;
        border: 2px solid #242424;
        background: none;
      }
    }
  }
}

.science-long-infogr{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 30px 0;

  &__item{
    width: 33%;
    text-align: center;
    margin: 0 0 30px 0;
    @media #{$mobile} {
      width: 50%;
    }

    &__icon{
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        fill: #EC1C24;
        max-width: 45px;
        max-height: 40px;
      }
    }

    &__text{
      @include bebas-book;
      font-size: 1.5rem;
      line-height: 1;
      max-width: 290px;
      margin: 0 auto;

      a{
        color: #242424;
        transition: .3s;
        &:hover{
          color: #EC1C24;
        }
      }
    }
  }
}

.charity-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  &__item{
    width: 48%;
    color: #242424;
    text-decoration: none;
    margin: 0 0 50px 0;
    @media #{$mobile} {
      width: 100%;
      margin: 0 0 30px 0;
    }

    &__img{
      position: relative;
      transition: .3s;
      margin: 0 0 20px 0;
      @media #{$mobile} {
        margin: 0 0 10px 0;
      }

      img{
        display: block;
        max-width: 100%;
      }

      &__line{
        position: absolute;
        background-color: white;
        transform: scale(0);
        transition: .3s;
        &._top{
          top: .5rem;
          left: .5rem;
          right: .5rem;
          height: 1px;
        }
        &._right{
          right: .5rem;
          top: .5rem;
          bottom: .5rem;
          width: 1px;
        }
        &._bottom{
          bottom: .5rem;
          left: .5rem;
          right: .5rem;
          height: 1px;
        }
        &._left{
          left: .5rem;
          top: .5rem;
          bottom: .5rem;
          width: 1px;
        }
      }
    }
    //&:hover &__img{
    //  filter: saturate(1.3);
    //
    //  &__line{
    //    transform: scale(1);
    //  }
    //}

    &__title{
      font-size: 1.25rem;
      transition: .3s;
    }
    //&:hover &__title{
    //  color: #EC1C24;
    //}

    &__preview{
      margin: 10px 0 0 0;
      font-size: .87rem;
    }
  }
}

.news-list-slider-container{
  position: relative;
}

.news-list-slider{
  overflow: hidden;
  @media #{$mobile} {
    text-align: left;
    margin: 0 -25px;
    padding: 0 25px;
  }
}

.news-list-slider-nav{

  &__prev, &__next{
    display: block;
    position: absolute;
    top: 125px;
    height: 8px;
    width: 34px;
    fill: #888888;
    cursor: pointer;
    @media #{$mobile} {
      z-index: 1;
      fill: #242424;
    }
    &:hover{
      fill: #242424;
    }
    &.swiper-button-disabled{
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .svg-icon{
      display: block;
    }
  }
  &__prev{
    left: -100px;
    @media #{$mobile} {
      left: -15px;
    }
  }
  &__next{
    right: -100px;
    @media #{$mobile} {
      right: -15px;
    }
  }
}

.text-transform-title{
  display: inline-block;
  position: relative;
  margin: 4rem 0 2rem -4.6rem;

  &:after{
    content: '';
    height: 1px;
    background-color: #242424;
    right: 0;
    left: 2.6rem;
    position: absolute;
    bottom: 0;
  }
}

.infogr{
  display: flex;
  flex-wrap: wrap;
  @media #{$mobile} {
    margin: 0 -25px;
  }

  &__grid{
    width: 25%;
    padding: 0 20px;
    @media #{$mobile} {
      width: 50%;
    }
  }
  &._coll-2 &__grid{
    width: 50%;
  }

  &__item{
    text-align: center;
    margin: 0 0 50px 0;
    @media #{$mobile} {
      margin: 0 0 25px 0;
    }

    &__icon{
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media #{$mobile} {
        height: 80px;
      }

      svg{
        fill: #EC1C24;
        max-width: 45px;
        max-height: 40px;
      }
    }

    &__text{
      @include bebas-book;
      font-size: 1.25rem;
      line-height: 1;

      a{
        color: #242424;
        transition: .3s;
        &:hover{
          color: #EC1C24;
        }
      }
    }

    &__text-min{
      color: #484848;
      font-size: .87rem;
      padding: 8px 0 0 0;
    }
  }
}

.nano-needles-list{
  display: flex;
  justify-content: space-between;
  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__item{
    width: 30%;
    background-color: white;
    padding: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media #{$mobile} {
      width: 100%;
      margin: 0 0 25px 0;
    }

    &__img{
      margin: 0 0 30px 0;
    }

    &__title{
      width: 100%;
      @include bebas;
      font-size: 1.5rem;
      line-height: 1;
    }

    &__descr{
      width: 100%;
      color: #484848;
      font-size: 0.87rem;
      margin: 11px 0 0 0;
    }

    &__descr-2{
      width: 100%;
      color: #6B6B6B;
      font-size: 0.87rem;
      margin: 22px 0 0 0;
    }
  }
}

.compare-nano-needle{
  display: flex;
  @media #{$mobile} {
    margin: 0 -25px;
  }

  &__grid{
    width: 33.333%;
  }

  &__item{
    text-align: center;
    padding: 30px;
    @media #{$mobile} {
      padding: 20px;
    }
    &._our{
      box-shadow: 0 2px 20px 0 rgba(214,214,214,0.50);
    }

    &__title{
      @include bebas-book;
      color: #6B6B6B;
      font-size: 1.5rem;
      margin: 0 0 25px 0;
      @media #{$mobile} {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
  }

  &__ul{
    margin: 20px 0 0 0;
    font-size: .87rem;
    @media #{$mobile} {
      margin-right: -30px;
    }
  }
}

.compare-react-nano-needle{
  display: flex;
  justify-content: space-between;

  &__item{
    width: 48%;

    &__title{
      @include bebas-book;
      color: #6B6B6B;
      font-size: 1.5rem;
      margin: 0 0 1rem 0;
    }

    &__img{

      img{
        max-width: 100%;
      }
    }
  }
}

.nano-needle-after-table{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0 0 0;
  @media #{$mobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  &__sticker{
    display: block;
  }

  h3{
    margin: 0;
  }
}

.contacts-clinics{
  display: flex;
  justify-content: space-between;
  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__item{
    width: 48%;
    border: 1px solid #E4DFE3;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px 50px;
    @media #{$mobile} {
      width: 100%;
      padding: 25px;
      margin: 0 0 25px 0;
    }

    &__title{
      @include bebas;
      font-size: 1.5rem;
      line-height: 1;
    }

    &__phone{
      font-size: .87rem;
      margin: 16px 0 0 0;
    }

    &__worktime{
      color: #888888;
      font-size: .87rem;
      margin: 11px 0 0 0;
    }
  }
}

.contacts-data{
  display: flex;
  justify-content: space-between;
  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__item{
    width: 33.333%;
    text-align: center;
    padding: 0 20px;
    @media #{$mobile} {
      width: 100%;
      padding: 0;
      margin: 0 0 30px 0;
      &:last-child{
        margin: 0;
      }
    }

    &__title{
      font-size: 2.15rem;
      line-height: 1;
      @include bebas-book;
    }

    &__address{
      text-transform: uppercase;
      margin: 20px 0 0 0;
      font-size: .87rem;
      @media #{$mobile} {
        margin: 10px 0 0 0;
      }
    }

    &__phone{
      margin: 20px 0 0 0;
      font-size: .87rem;
      @media #{$mobile} {
        margin: 10px 0 0 0;
      }
    }
  }
}

.head-404{
  position: relative;

  &__logo{
    position: absolute!important;
    left: 0;
    top: 27px;
  }
}

.page-404{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  &__404{
    @include bebas-book;
    color: #EC1C24;
    font-size: 10.75rem;
    line-height: 1;
  }

  &__not-found{
    font-size: 1.75rem;
    @include bebas-book;
    line-height: 1;
  }

  &__text{
    margin: 3rem 0 0 0;
  }

  &__link{
    margin: 2rem 0 0 0;

    a{
      color: inherit;
    }
  }
}

.preloader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-image: url("/img/preloader.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(233, 231, 234, .87);
  animation: preloader .3s;
  &._remove{
    animation: preloader-remove .3s;
  }
}
@keyframes preloader {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes preloader-remove {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.content-popup{
  width: 600px;
  max-width: 100%;
}

pre{
  border: 1px solid #E4DFE3;
  font-size: .5rem;
  background-color: white;
  margin: 10px;
  padding: 10px;
}

.faq-form{

  h3,
  .form-submit{
    text-align: center;
  }
}

.nano-needle-advantages{
  display: flex;
  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__item{
    width: 33.333%;
    padding: 0 50px 0 0;
    @media #{$mobile} {
      width: 100%;
      padding: 0;
      margin: 0 0 30px 0;
      &:last-child{
        margin: 0;
      }
    }

    &__icon{
      margin: 0 0 32px 0;
      @media #{$mobile} {
        margin: 0 0 20px 0;
      }

      svg{
        fill: #EC1C24;
        max-width: 45px;
        max-height: 40px;
      }
    }

    &__text{
      font-size: 1.25rem;
      line-height: 1;
      @include bebas-book;
      margin: 0 0 23px 0;
      @media #{$mobile} {
        margin: 0 0 15px 0;
      }
    }
  }
}

.events-prev-list{
  display: flex;
  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__item{
    width: 33.333%;
    margin: 0 30px 0 0;
    background-color: white;
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &:last-child{
      margin-right: 0;
    }
    @media #{$mobile} {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 30px;
    }

    &__type{
      @include bebas-book;
      color: #EC1C24;
      font-size: 1.25rem;
      line-height: 1;
      margin: 0 0 20px 0;

      a{
        color: inherit;
        text-decoration: none;
      }
    }

    &__leading{
      @include bebas-book;
      font-size: 1.75rem;
      line-height: 1;
      margin: 0 0 5px 0;
    }

    &__leading-post{
      color: #888888;
      font-size: .87rem;
      margin: 0 0 20px 0;
    }

    &__name{
      color: #484848;
      font-size: .87rem;
      margin: 0 0 25px 0;

      a{
        @include title-link;
      }
    }

    &__coord{
      color: #888888;
      font-size: .87rem;
      text-transform: uppercase;
      margin: 0 0 17px 0;
    }

    &__price{
      @include bebas-book;
      font-size: 1.75rem;
      line-height: 1;
      margin: 0 0 20px 0;
    }
  }
}

.events-list{

  &__item{
    display: flex;
    justify-content: space-between;
    @media #{$mobile} {
      flex-wrap: wrap;
    }

    &__left, &__right{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__left{
      width: 40%;
      @media #{$mobile} {
        width: 100%;
      }
    }
    &__right{
      width: 220px;
      @media #{$mobile} {
        width: 100%;
      }
    }

    &__type{
      @include bebas-book;
      color: #EC1C24;
      font-size: 1.25rem;
      line-height: 1;
      margin: 0 0 20px 0;

      a{
        color: inherit;
        text-decoration: none;
      }
    }

    &__leading{
      @include bebas-book;
      font-size: 1.75rem;
      line-height: 1;
      margin: 0 0 5px 0;
    }

    &__leading-post{
      color: #888888;
      font-size: .87rem;
      margin: 0 0 20px 0;
    }

    &__name{
      color: #484848;
      font-size: .87rem;

      a{
        @include title-link;
      }
    }

    &__coord{
      font-size: .87rem;
      text-transform: uppercase;
      margin: 0 0 17px 0;
      @media #{$mobile} {
        margin: 17px 0;
      }
    }

    &__price{
      @include bebas-book;
      font-size: 1.75rem;
      line-height: 1;
      margin: 0 0 20px 0;
    }
  }
}

.photo-slider{
  overflow: hidden;
  @media #{$mobile} {
    margin: 0 -25px;
  }

  &__container{
    position: relative;
  }

  &__slide{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    a{
      display: block;
      text-decoration: none;
    }

    img{
      display: block;
      max-width: 100%;
    }
  }

  &__prev, &__next{
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    height: 8px;
    width: 34px;
    fill: #888888;
    cursor: pointer;
    z-index: 1;
    @media #{$mobile} {
      fill: #242424;
    }
    &:hover{
      fill: #242424;
    }
    &.swiper-button-disabled{
      opacity: 0;
      visibility: hidden;
    }

    .svg-icon{
      display: block;
    }
  }
  &__prev{
    left: -50px;
    @media #{$mobile} {
      left: 0;
    }
  }
  &__next{
    right: -50px;
    @media #{$mobile} {
      right: 0;
    }
  }
}

.subscribe{
  max-width: 420px;
  margin: 0 auto;
  position: relative;

  [type=text]{
    background-color: #FAF9FA;
  }

  [type=submit]{
    appearance: none;
    border: none;
    background: none;
    position: absolute;
    padding: 0;
    cursor: pointer;
    right: 35px;
    top: 50%;
    margin-top: -4px;

    .svg-icon{
      width: 18px;
      height: 8px;
      display: block;
    }
  }

  .form-item{

    &._error{
      padding-bottom: 0;
    }

    &__error{
      bottom: auto;
      top: 100%;
    }
  }
}

.title-link{
  @include title-link;
}

.stages-title {
  color: #242424;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 40px;
}

.stages {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  @media #{$mobile} {
    flex-direction: column;
  }
}

.stage {
  width: calc(100% / 4);
  padding: 0 20px 0 0;
  box-sizing: border-box;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 50px;
    right: 10px;
    height: 4px;
    background: url('/img/bg_dot.png') 0 0 repeat-x;
    z-index: 1;
  }
  @media #{$mobile} {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
    &:before {
      content: none;
    }
  }
}

.stage_last:before {
  content: none;
}

.stage__point {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-family: 'opensans', sans-serif;
  line-height: 60px;
  font-size: 27px;
  color: #ffffff;
  background-color: #f89b26;
  border-radius: 50%;
  position: relative;
  z-index: 5;
  margin-bottom: 15px;
}

.stages p {
  line-height: 22px;
}

.stage a {
  color: #242424;
  text-decoration: underline;
  &:hover, &:visited {
    color: #242424;
  }
}

.checklist a {
  color: #242424;
  text-decoration: underline;
  &:hover, &:visited {
    color: #242424;
  }
}

.stage-note {
  max-width: 60%;
  margin-bottom: 60px;
  p {
    line-height: 1.2;
  }
  @media #{$mobile} {
    max-width: 100%;
  }
}

.checklist {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.checklist__icon {
  padding-right: 20px;
  flex-shrink: 0;
}

.checklist-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #f89b26;
  border-radius: 50%;
  position: relative;
  &:before {
    content: '';
    width: 25px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12.5px 0 0 -12px;
    background: url('/img/icon_doc-zoom.png') 0 0 no-repeat;
  }
}

.checklist p {
  line-height: 1.2;
  margin-bottom: 15px;
}
.icon {
  display: inline-block;
}
.section-head {
  &__icon {
    margin-bottom: 10px;
  }
}
.franchise-title {
  @include bebas-book;
  font-size: 3.75rem;
  color: #242424;
  letter-spacing: -0.5px;
  margin-bottom: 1.75rem;
  line-height: 1;
}
.paragraph {
  margin-bottom: 1.25rem;
  color: #242424;
}
.product-col {
  margin: 15px 0;
}
.product {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #ffffff;
  background-position: 50% 0;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 160px 45px 95px 45px;
  margin-bottom: 30px;
  &__title {
    @include bebas;
    color: #242424;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    margin-bottom: 1rem;
  }
  &__btn {
    position: absolute;
    bottom: 30px;
    left: 45px;
  }
}
.product-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.franchise-form {
  width: 100%;
  max-width: 552px;
  margin: 0 auto;
  &__title {
    color: #242424;
    @include bebas-book;
    font-size: 2.25rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    margin-bottom: 3.125rem;
    text-align: center;
  }
  &__submit {
    margin-top: 1.725rem;
    text-align: center;
  }
}
.t-a-c {
  text-align: center !important;
}
.benefit {
  padding: 0 2.5rem;
}
.benefit-col {
  margin: 2.5rem 0;
}
.benefit-text {
  @include bebas-book;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #242424;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin-top: 15px;
}
.base-slider-title {
  margin-bottom: 3.75rem;
}
.base-img {
  width: 110px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.875rem auto;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.base-text {
  @include bebas-book;
  font-size: 1.25rem;
  line-height: 1.1;
  color: #242424;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 2.5rem;
}
.base-slider-container {
  position: relative;
}
.base-slider {
  overflow: hidden;
  padding-bottom: 4.375rem;
  @media #{$mobile} {
    text-align: left;
    margin: 0 -25px;
    padding: 0 25px;
  }
  &__slide {
    text-align: center;
  }
  &__pagination{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 16px;
    .bullet{
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #242424;
      margin: 0 14px 0 0;
      transition: .3s;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &._active{
        width: 16px;
        height: 16px;
        border: 2px solid #242424;
        background: none;
      }
    }
  }
}
.base-slider-nav {
  &__prev, &__next{
    display: block;
    position: absolute;
    top: 50%;
    height: 7px;
    width: 25px;
    fill: #000000;
    cursor: pointer;
    z-index: 10;
    @media #{$mobile} {
      z-index: 1;
      fill: #242424;
    }
    &:hover{
      fill: #242424;
    }
    &.swiper-button-disabled{
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    .svg-icon{
      display: block;
    }
  }
  &__prev{
    left: 0;
    @media #{$mobile} {
      left: -15px;
    }
  }
  &__next{
    right: 0;
    @media #{$mobile} {
      right: -15px;
    }
  }
}
.mt-65 {
  margin-top: 4.0625rem;
}
.technologies {
  display: flex;
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  position: relative;
  &:before {
    content: '';
    width: 135px;
    height: 136px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -68px 0 0 -67.5px;
    background: url('/img/franchise/rhana_logo.png') 0 0 no-repeat;
  }
}
.technology {
  width: 50%;
  &_left {
    text-align: right;
    padding-right: 122.5px;
  }  
  &_right {
    text-align: left;
    padding-left: 122.5px;
  }
  &__item {
    margin: 10px 0;
  }
  &__link {
    @include bebas-book;
    font-size: 1.5rem;
    line-height: 1;
    color: #242424;
    letter-spacing: 0.6px;
    text-decoration: none;
  }
}
.app-container {
  width: 100%;
  max-width: 1080px;
}
.application-col {
  margin: 1.875rem 0;
  font-size: 0;
}
.application-icon {
  height: 40px;
  img {
    max-height: 100%;
  }
}
.application-text {
  @include bebas-book;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #242424;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin-top: 15px;
}
.popup-gallery {
  width: 720px;
  padding: 1.875rem 4.25rem;
  position: relative;
}
.certificates-slider {
  padding-bottom: 0;
}
.certificate-img {
  text-align: center;
  img {
    max-width: 290px;
    margin-bottom: 4rem;
  }
}
.certificate-text {
  color: #242424;
  font-family: 'opensans', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  padding: 0 2.5rem;
}
.certificates-slider-nav {
  top: 130px;
  margin-top: 0;
  &__prev {
    left: 70px;
  }
  &__next {
    right: 70px;
  }
}
.main-up-arrow{
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;

  &._active{
    opacity: 1;
    visibility: visible;
  }
}
.subheader {
  position: absolute;
  top: 34px;
  z-index: 10;
  right: 200px;
  &__link {
    color: #242424;
    font-size: 0.875rem;
    line-height: 24px;
    letter-spacing: 0.47px;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
  }
}