.form-item{
  margin: 0 0 1.4rem 0;
  position: relative;
  transition: padding .3s;
  &_checkbox{
    margin-bottom: 1.5rem;
  }
  &._error{
    padding-bottom: 20px;
  }

  &__label{
    display: block;
    font-size: .87rem;
    margin: 0 0 .37rem 0;
  }
  &._disabled &__label{
    color: #A8A8A8;
  }

  &__error{
    color: #EC1C24;
    font-size: .87rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &._error &__error{
    opacity: 1;
    visibility: visible;
  }

  input[type=text],
  input[type=tel],
  input[type=password],
  input[type=email]{
    appearance: none;
    outline: none;
    width: 100%;
    height: 3.12rem;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    @include opensans;
    font-size: .87rem;
    padding: 0 .9rem;
    transition: .3s;
    &:focus{
      border-color: #C3AFAF;
    }
    &:disabled{
      background-color: #F1F1F1;
      color: #A8A8A8;
    }
  }
  &._error input[type=text],
  &._error input[type=tel],
  &._error input[type=password],
  &._error input[type=email]{
    border-color: #EC1C24;
    color: #EC1C24;
  }

  textarea{
    appearance: none;
    outline: none;
    resize: none;
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    @include opensans;
    font-size: .87rem;
    padding: .9rem;
    transition: .3s;
    &:focus{
      border-color: #C3AFAF;
    }
    &:disabled{
      background-color: #F1F1F1;
      color: #A8A8A8;
    }
  }
  &._error textarea{
    border-color: #EC1C24;
    color: #EC1C24;
  }

  select{
    appearance: none;
    outline: none;
    width: 100%;
    height: 50px;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    @include opensans;
    font-size: .87rem;
    background-color: white;
    padding: 0 .9rem;
    background-image: url("/img/select.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 1.25rem) center;
  }

  //checkbox
  input[type=checkbox]{
    display: none;
  }
  input[type=checkbox] + label{
    position: relative;
    padding: 0 0 0 2.5rem;
    display: block;

    &:before{
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #DDDDDD;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s;
      background: url("../img/checkbox.svg") no-repeat white;
      background-position: center -110%;
    }
    &:hover:before{
      border-color: #B4A0A1;
    }

    a{
      @include link_3;
    }
  }
  input[type=checkbox]:checked + label{

    &:before{
      background-position: center;
    }
  }
  input[type=checkbox]:disabled + label{

    &:before{
      background-color: #F1F1F1;
      border-color: #F1F1F1;
    }
  }
  input[type=checkbox]:disabled:checked + label{

    &:before{
      background-image: url("../img/checkbox-disabled.svg");
    }
  }
  &._error input[type=checkbox] + label{

    &:before{
      border-color: #EC1C24;
    }
  }

  //radio
  input[type=radio]{
    display: none;
  }
  input[type=radio] + label{
    position: relative;
    padding: 0 0 0 2.5rem;

    &:before{
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #DDDDDD;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s;
      background: url("../img/radio.svg") no-repeat white;
      background-position: -100% center;
    }
    &:hover:before{
      border-color: #B4A0A1;
    }
  }
  input[type=radio]:checked + label{

    &:before{
      background-position: center;
    }
  }
  input[type=radio]:disabled + label{

    &:before{
      background-color: #F1F1F1;
      border-color: #F1F1F1;
    }
  }
  input[type=radio]:disabled:checked + label{

    &:before{
      background-image: url("../img/radio-disabled.svg");
    }
  }
}

.form-submit{

  button[type=submit]{
    appearance: none;
    outline: none;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    border: 2px solid #242424;
    padding: .5rem 1rem;
    text-transform: uppercase;
    font-size: .87rem;
    color: #242424;
    position: relative;
    transition: .2s;
    background-color: white;
    &:hover:not(:disabled){
      color: white;
    }
    &:disabled{
      cursor: default;
      border-color: #E4DFE3;
      color: #A8A8A8;
    }

    &:before{
      content: '';
      background-color: #242424;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
      transition: .2s;
      opacity: 0;
    }
    &:not(:disabled):hover:before{
      left: 0;
      right: 0;
      opacity: 1;
    }

    span{
      position: relative;
    }
  }
}

.form-in-contacts{

  .form-submit{
    text-align: center;
  }
}